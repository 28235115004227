body {
  margin: 0;
  font-family: 'Spectral';
  background-color: #f2f2f2;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
