.b19ynxv2{width:100%;margin:auto 0 0 0;padding:0px 6px;}
.cwenigj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen and (max-width:767px){.cwenigj{padding:15px;}}@media screen and (min-width:768px) and (max-width:1429px){.cwenigj{padding:20px;}}@media screen and (min-width:1430px){.cwenigj{padding:20px;}}
.s1xt9mq1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;font-size:13px;}@media screen and (max-width:767px){.s1xt9mq1{margin-bottom:10px;height:25px;}}@media screen and (min-width:768px) and (max-width:1429px){.s1xt9mq1{margin-bottom:15px;height:25px;}}@media screen and (min-width:1430px){.s1xt9mq1{margin-bottom:15px;height:25px;}}
.ar3awak{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:2px 15px;}
.aasp8up{display:inline-block;margin-left:5px;width:20px;height:20px;border-radius:50%;background-color:var(--aasp8up-0);}
.ok6xjt3{overflow-y:scroll;width:100%;}@media screen and (max-width:767px){.ok6xjt3{height:29vh;}}@media screen and (min-width:768px) and (max-width:1429px){.ok6xjt3{height:300px;}}@media screen and (min-width:1430px){.ok6xjt3{height:300px;}}
.f1lbx2zy{background-color:var(--f1lbx2zy-0);color:black;display:inline-block;margin:4px 6px;padding:1px;cursor:pointer;-webkit-transition:200ms;transition:200ms;-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out;opacity:var(--f1lbx2zy-2);}@media screen and (max-width:767px){.f1lbx2zy{font-size:13px;}}@media screen and (min-width:768px) and (max-width:1429px){.f1lbx2zy{font-size:14px;}}@media screen and (min-width:1430px){.f1lbx2zy{font-size:14px;}}
.l1it2m1i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen and (max-width:767px){.l1it2m1i{margin:10px 0;font-size:14px;}}@media screen and (min-width:768px) and (max-width:1429px){.l1it2m1i{margin:15px 0;font-size:14px;}}@media screen and (min-width:1430px){.l1it2m1i{margin:15px 0;font-size:14px;}}
.w4a4gvy{margin-right:4px;color:#ff5757;}
.cqjca77{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
