.p16trfel{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-bottom:1px #c4c4c4 solid;background-color:white;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen and (max-width:767px){.p16trfel{line-height:1.1;}}
.ap24396{font-weight:300;font-size:13px;}@media screen and (max-width:767px){.ap24396{font-size:11px;}}@media screen and (min-width:768px) and (max-width:1429px){.ap24396{font-size:13px;}}@media screen and (min-width:1430px){.ap24396{font-size:13px;}}
.dsyskdh{margin-left:10px;margin-right:10px;font-size:13px;}
.f1a5gfx2{padding-top:5px;}
.c1g96kgm{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:space-evenly;-webkit-justify-content:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly;}@media screen and (max-width:767px){.c1g96kgm{height:60%;width:85%;padding:25px 0px;margin:0 auto;}}@media screen and (min-width:768px) and (max-width:1429px){.c1g96kgm{height:60%;width:75%;padding:40px 0px;}}@media screen and (min-width:1430px){.c1g96kgm{height:60%;width:75%;padding:40px 0px;}}
.l17rhavk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;cursor:var(--l17rhavk-0);fill:var(--l17rhavk-1);}.l17rhavk:hover svg{fill:var(--l17rhavk-2);}.l17rhavk svg{fill:var(--l17rhavk-1);}@media screen and (max-width:767px){.l17rhavk svg{width:15px;}}@media screen and (min-width:768px) and (max-width:1429px){.l17rhavk svg{width:20px;}}@media screen and (min-width:1430px){.l17rhavk svg{width:20px;}}@media screen and (max-width:767px){.l17rhavk{margin:5px;bottom:0;right:0px;}}@media screen and (min-width:768px) and (max-width:1429px){.l17rhavk{margin:10px;bottom:0;right:0px;}}@media screen and (min-width:1430px){.l17rhavk{margin:10px;bottom:0;right:0px;}}
.ln3ilzw{font-size:13px;font-weight:300;margin-left:5px;}
.c1do5f1h{border-radius:50%;border:1px solid #b5b5b5;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen and (min-width:768px) and (max-width:1429px){.c1do5f1h{width:70px;height:70px;margin:0 20px;}}@media screen and (min-width:1430px){.c1do5f1h{width:90px;height:90px;margin:0 30px;}}
.l13kp5s5{font-size:44px;color:#676767;position:relative;top:3px;}@media screen and (max-width:767px){.l13kp5s5{font-size:30px;}}@media screen and (min-width:768px) and (max-width:1429px){.l13kp5s5{font-size:44px;}}@media screen and (min-width:1430px){.l13kp5s5{font-size:44px;}}
.pbwbuy2{cursor:pointer;}
